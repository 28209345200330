import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>About Cogniworks</h1>

    <ol style={{ listStyleType: "none", marginLeft: 0 }}>
      <li>Cogniworks Limited</li>
      <li>259 Otley Road</li>
      <li>Leeds</li>
      <li>LS16 5LQ</li>
      <li>United Kingdom</li>
    </ol>
    
    <p>Registered Company 08516909</p>
    <p>VAT Registration 187 6792 41</p>
  </Layout>
)

export default AboutPage
